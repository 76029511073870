import React from 'react';
import './css/Markets.scss';

import { isDev, server, stripePromise, currencyMask } from './MarketsUtils.js';

import MaskedInput from 'react-text-mask'
import { ImArrowRight2 } from 'react-icons/im';
import { useAuth0 } from "@auth0/auth0-react";

var LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <button 
      className="account-login-button" 
      onClick={() => loginWithRedirect()}
    >
      Log In
    </button>
  );
}

export var LogOutButton = () => {
  const { logout } = useAuth0();
  return (
    <div
      className="account-button account-logout-button" 
      onClick={() => logout({returnTo: isDev ? "http://localhost:3000" : "https://thisforthat.co"})}
    >
      Log Out
    </div>
  );
}

class AccountPreLogin extends React.Component {
  render() {
    return (
      <div>
        <div className="account-prelogin-howitworks-title">
          How it Works
        </div>
        <div className="account-prelogin-howitworks-divider" />
        <div className="account-prelogin-howitworks-body">
          <p>
            <strong>This For That</strong> lets you bet on cities.
            Just like a traditional stock market lets you buy and sell
            shares of companies, This For That lets you trade city shares.
          </p>
          <p>
            Invest in undervalued cities and sell overvalued ones.
            In addition to profit on market movements, you will
            receive dividends if any of your portfolio cities
            issues one.
          </p>
          <p>
            Note that This For That is still experimental.
            Your shares may be sold at any time at market rate.
          </p>
        </div>
        <LoginButton />
      </div>
    );
  }
}

class AccountPostLogin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      revealDepositForm: false,
      revealWithdrawForm: false,
      depositWithdrawInputValue: '',
    };
    this.toggleRevealDepositButton = this.toggleRevealDepositButton.bind(this);
    this.toggleRevealWithdrawButton = this.toggleRevealWithdrawButton.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
  }

  componentDidMount() {
    this.props.fetchAccountInfo();
  }
  
  getCashComponent() {
    if (this.props.accountInfo.totalDividends > 0.0001) {
      return (
        <div className="account-postlogin-cash">
          Available cash: ${this.props.accountInfo.balance.toFixed(2)}
          <div className="account-postlogin-dividends">
            (Includes ${this.props.accountInfo.totalDividends.toFixed(4)} from dividends)
          </div>
        </div>
      );
    } else {
      return (
        <div className="account-postlogin-cash">
          Available cash: ${this.props.accountInfo.balance.toFixed(2)}
        </div>
      );
    }
  }

  getPositionsComponent() {
    if (this.props.accountInfo.positions.length > 0) {
      return (
        <div className="account-postlogin-positions">
          Current Portfolio
          {this.props.accountInfo.positions.map((pos) => {
            if (pos.ticker in this.props.tickerInfos) {
              return (
                <div key={pos.ticker} className="account-postlogin-position">
                  <strong>{pos.numShares}</strong> Shares of{' '}
                  <strong>{this.props.tickerInfos[pos.ticker].readableName}</strong>
                </div>
              );
            } else {
              return (
                <div key={pos.ticker} className="account-postlogin-position">
                  {pos.numShares} Shares of <strong>{pos.ticker}</strong>
                </div>
              );
            }
          })}
        </div>
      );
    } else {
      return (
        <div className="account-postlogin-positions">
          No stocks purchased.
        </div>
      );
    }
  }

  getOpenLimitOrdersComponent() {
    var openOrders = this.props.accountInfo.openOrders;
    if (openOrders.length > 0) {
      return (
        <div className="account-postlogin-openlimitorders">
          Pending Orders
          {openOrders.map((openOrder) => {
            return (
              <div
                key={openOrder.orderId}
                className="account-postlogin-openlimitorder"
              >
                {openOrder.isBuy ? "Buying" : "Selling"}{" "}
                <strong>{openOrder.numOpenShares}</strong>{" "}
                shares of{" "}
                <strong>{
                  openOrder.ticker in this.props.tickerInfos ? 
                    this.props.tickerInfos[openOrder.ticker].readableName : 
                    openOrder.ticker
                }</strong>{" "}
                at{" "}
                <strong>${openOrder.price.toFixed(2)}</strong>{" "}
                <span
                  className="account-postlogin-openlimitorder-cancel"
                  onClick={(event) => this.cancelOrder(event, openOrder.orderId)}
                >
                  (Cancel)
                </span>
              </div>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }

  getExternalTransactionsComponent(isNewUser) {
    if (isNewUser) {
      return (
        <div className="account-postlogin-externaltransactions">
          {this.getDepositWithdrawComponent(true, "Deposit Cash", true)}
        </div>
      );
    } else {
      return (
        <div className="account-postlogin-externaltransactions">
          {this.getDepositWithdrawComponent(true, "Deposit Cash", false)}
          {this.getDepositWithdrawComponent(false, "Withdraw Cash", false)}
        </div>
      );
    }
  }

  getDepositWithdrawComponent(isDeposit, text, isSingleButton) {

    var clickCallback = isDeposit ?
      this.toggleRevealDepositButton : this.toggleRevealWithdrawButton;
    var shouldRevealStr = isDeposit ? "revealDepositForm" : "revealWithdrawForm"

    if (this.state[shouldRevealStr]) {
      return (
        <div
          className={
            "account-button account-postlogin-depositwithdraw-revealed" + 
            (isSingleButton ? "-single" : "")
          }
          onClick={clickCallback}
        >
          {text}
          <div className="account-postlogin-depositwithdraw-revealed-content"
            onClick={clickCallback}
          >
            Amount:
            <MaskedInput
              className="account-postlogin-depositwithdraw-revealed-content-input"
              mask={currencyMask} 
              onChange={(e) => this.setState({depositWithdrawInputValue: e.target.value})}
            />
            <ImArrowRight2
              onClick={(event) => 
                this.handleDepositCashOnClick(event, this.state.depositWithdrawInputValue)}
              size="25"
            />
          </div>
        </div>
      )
    } else if (!this.state.revealDepositForm && !this.state.revealWithdrawForm) {
      return (
        <div
          className={
            "account-button account-postlogin-depositwithdraw-button" + 
            (isSingleButton ? "-single" : "")
          }
          onClick={clickCallback}
        >
          {text}
        </div>
      );
    }

  }

  toggleRevealDepositButton(event) {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      this.setState({revealDepositForm: !this.state.revealDepositForm});
    }
  }

  toggleRevealWithdrawButton(event) {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      this.setState({revealWithdrawForm: !this.state.revealWithdrawForm});
    }
  }

  async handleDepositCashOnClick(event, inputValue) {
    var amountInCents = Math.round(100 * parseFloat(inputValue.slice(1).replace(',', '')));
    if (isNaN(amountInCents)) {
      return;
    }
    const stripe = await stripePromise;
    const response = await server.get(
      `/action/createDepositSession/${this.props.user.sub}?amountInCents=${amountInCents}`);
    await stripe.redirectToCheckout({
      sessionId: response.data.id,
    });
  }

  cancelOrder(event, orderId) {
    server.get(`/action/cancelLimitOrder?orderId=${orderId}`).then((response) => {
      this.props.fetchAccountInfo();
    });
  }

  render() {
    if (this.props.createdOrConfirmed === null || this.props.accountInfo === null) {
      return null;
    }
    if (this.props.accountInfo.isNewUser) {
      return (
        <div>
          <div className="account-postlogin-newuser">
            Deposit some cash into your account to get started trading.
          </div>
          {this.getExternalTransactionsComponent(true)}
          {this.props.isMobile ? null : <LogOutButton />}
        </div>
      );
    } else {
      return (
        <div>
          {this.getCashComponent()}
          {this.getPositionsComponent()}
          {this.getOpenLimitOrdersComponent()}
          {this.getExternalTransactionsComponent(false)}
          {this.props.isMobile ? null : <LogOutButton />}
        </div>
      );
    }
  }

}

export const Account = (props) => {
  var content = props.isAuthenticated ? 
    <AccountPostLogin 
      isMobile={props.isMobile}
      user={props.user}
      accountInfo={props.accountInfo}
      tickerInfos={props.tickerInfos}
      fetchAccountInfo={props.fetchAccountInfo}
    /> : <AccountPreLogin />;
  return (
    <div className={"account" + (props.isLoading ? " hidden" : "")}>
      <div className="account-title">
        This For That
      </div>
      {content}
    </div>
  );
}


import React from 'react';
import './css/Markets.scss';

import ReactGA from "react-ga";
import { useMediaQuery } from 'react-responsive'
import update from 'immutability-helper';
import { useAuth0 } from "@auth0/auth0-react";
import { GridList, GridListTile } from '@material-ui/core';

import { Account, LogOutButton } from './MarketsAccount.js';
import Tickers from './MarketsTickers.js';
import { server } from './MarketsUtils.js';

class MarketsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      createdOrConfirmed: null,
      accountInfo: null,
      tickerInfos: {},
    }
    this.fetchAccountInfo = this.fetchAccountInfo.bind(this);
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.fetchAccountInfo();
  }

  fetchAccountInfo() {
    if (!this.props.isAuthenticated) {
      return;
    }
    server.get(
      `/action/createOrConfirmAccount/${this.props.user.sub}?email=${this.props.user.email}&name=${this.props.user.name}`
    ).then((response) => {
      this.setState({ createdOrConfirmed: response.data });
      server.get(`/account/getAccountInfo/${this.props.user.sub}`).then((response) => {
        this.setState({ accountInfo: response.data });
        for (let i = 0; i < response.data.positions.length; i++) {
          let ticker = response.data.positions[i]["ticker"];
          server.get(`/ticker/getTickerInfo/${ticker}`).then((response) => {
            this.setState({
              tickerInfos: update(this.state.tickerInfos, {[ticker]: {$set: response.data}})
            })
          })
        }
      });
    });
  }

  render() {
    if (this.props.isMobile) {
      return (
        <div className="markets">
          <GridList
            className="gridlist"
            cellHeight="auto"
            cols={1}
          >
            <GridListTile key="account" cols={1}>
              <Account 
                isMobile={this.props.isMobile}
                user={this.props.user}
                isLoading={this.props.isLoading}
                isAuthenticated={this.props.isAuthenticated}
                createdOrConfirmed={this.state.createdOrConfirmed}
                accountInfo={this.state.accountInfo}
                tickerInfos={this.state.tickerInfos}
                fetchAccountInfo={this.fetchAccountInfo}
              />
            </GridListTile>
            <Tickers
              isMobile={this.props.isMobile}
              user={this.props.user}
              isAuthenticated={this.props.isAuthenticated}
              fetchAccountInfo={this.fetchAccountInfo}
            />
            {
              this.props.isAuthenticated ? 
                <GridListTile key="logoutbutton" cols={1}>
                  <LogOutButton />
                </GridListTile>
              : null
            }
          </GridList>
        </div>
      );
    } else {
      return (
        <div className="markets">
          <Account 
            isMobile={this.props.isMobile}
            user={this.props.user}
            isLoading={this.props.isLoading}
            isAuthenticated={this.props.isAuthenticated}
            createdOrConfirmed={this.state.createdOrConfirmed}
            accountInfo={this.state.accountInfo}
            tickerInfos={this.state.tickerInfos}
            fetchAccountInfo={this.fetchAccountInfo}
          />
          <Tickers
            isMobile={this.props.isMobile}
            user={this.props.user}
            isAuthenticated={this.props.isAuthenticated}
            fetchAccountInfo={this.fetchAccountInfo}
          />
        </div>
      );
    }
  }
}

const Markets = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  return (
    <MarketsPage
      isMobile={isMobile}
      user={user}
      isLoading={isLoading}
      isAuthenticated={isAuthenticated}
    />
  );
}

export default Markets;


import axios from 'axios';
import { loadStripe } from "@stripe/stripe-js";
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
export const server = axios.create({
  baseURL: isDev ? "http://localhost:8080/" : "https://api.thisforthat.co/"
});

var stripePublicKey
if (isDev) {
  stripePublicKey = "pk_test_51ICtX3HmPMYCWSDxk65RnkP3SncSfrS5rwFuPzk3jwWkMsAl7LTH1w3CKN1ZFoHQB7g4oOpTO0tEBqoXRC3QPtFz00OoxEtZvn";
} else {
  stripePublicKey = "pk_live_51ICtX3HmPMYCWSDxfg3ACEwYZucJ2duxgDNlf2c1Z8R13uL6UUrV0x41RFZ806BoDVrlIpjAZ3CDWrH0ssvSmSkZ00tb30Zr6R";
}
export const stripePromise = loadStripe(stripePublicKey);

export const currencyMask = createNumberMask({
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2,
  integerLimit: 6,
  allowNegative: false,
  allowLeadingZeroes: false,
});

export const naturalMask = createNumberMask({
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: false,
  integerLimit: 6,
  allowNegative: false,
  allowLeadingZeroes: false,
});

/* A unicode WORD JOINER: a zero-width non-breaking space. When a div is
empty, but will be soon populated by a response from the server, you can render
just a FORCE_RENDER in the div in order to force the height of the div to be
equal to the height of the future text. */
export const FORCE_RENDER = "\u2060";


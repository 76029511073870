import React from 'react';
import './css/Markets.scss';

import { server, currencyMask, naturalMask, FORCE_RENDER } from './MarketsUtils.js';

import ReactGA from "react-ga";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import { GridList, GridListTile } from '@material-ui/core';
import { IoCloseOutline } from 'react-icons/io5';

import MaskedInput from 'react-text-mask'

import imgSf from './img/cities/sf.png';
import imgAlameda from './img/cities/alameda.png';
import imgMiami from './img/cities/miami.png';
import imgVegas from './img/cities/vegas.png';
import imgSantaclara from './img/cities/santaclara.png';
import imgDc from './img/cities/dc.png';
import imgNyc from './img/cities/nyc.png';
import imgSeattle from './img/cities/seattle.png';

const tickerToImage = {
  "sf": imgSf,
  "alameda": imgAlameda,
  "miami": imgMiami,
  "vegas": imgVegas,
  "santaclara": imgSantaclara,
  "dc": imgDc,
  "nyc": imgNyc,
  "seattle": imgSeattle,
}

class ExpandedTicker extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tickerInfo: {},
      orderBook: {buy: [], sell: []},
      redirectPath: "",
      limitOrderInputPrice: "",
      limitOrderInputNumShares: "",
      limitOrderIsBuy: true,
      limitOrderConfirmedMessage: "",
      marketOrderInputNumShares: "",
      marketOrderIsBuy: true,
      marketOrderCurrentPriceString: "",
      marketOrderConfirmedMessage: "",
    };
    this.fetchOrderBook = this.fetchOrderBook.bind(this);
    this.onClickX = this.onClickX.bind(this);
    this.goToLimitOrderButton = this.goToLimitOrderButton.bind(this);
    this.goToMarketOrderButton = this.goToMarketOrderButton.bind(this);
    this.limitOrderToggleBuySell = this.limitOrderToggleBuySell.bind(this);
    this.submitLimitOrder = this.submitLimitOrder.bind(this);
    this.marketOrderToggleBuySell = this.marketOrderToggleBuySell.bind(this);
    this.marketOrderSetNumShares = this.marketOrderSetNumShares.bind(this);
    this.submitMarketOrder = this.submitMarketOrder.bind(this);
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    server.get(`/ticker/getTickerInfo/${this.props.ticker}`).then((response) => {
      this.setState({ tickerInfo: response.data });
    });
    this.fetchOrderBook();
    setInterval(this.fetchOrderBook, 5000);
  }

  fetchOrderBook() {
    server.get(`/ticker/getOrderBook/${this.props.ticker}`).then((response) => {
      this.setState({ orderBook: response.data });
    });
  }

  onClickX(event) {
    this.setState({ redirectPath: "/markets" });
  }

  getTitle() {
    var title = this.state.tickerInfo.county || this.state.tickerInfo.readableName || FORCE_RENDER;
    if (this.state.tickerInfo.majorCities) {
      return (
        <div className="expanded-ticker-title">
          <strong>{title}</strong>
          <div className="expanded-ticker-subtitle">
            ({this.state.tickerInfo.majorCities})
          </div>
        </div>
      );
    } else {
      return (
        <div className="expanded-ticker-title">
          <strong>{title}</strong>
        </div>
      );
    }
  }

  getContent() {
    if (this.props.orderType === null) {
      var yearlyInterest = this.state.tickerInfo.yearlyRevenue 
        * this.state.tickerInfo.fractionRevenueReturnedPerShare;
      var interestDiv;
      if (isNaN(yearlyInterest)) {
        interestDiv = (
          <div>
            <div className="expanded-ticker-interest">
              {FORCE_RENDER}
            </div>
            <div className="expanded-ticker-interest-sub">
              {FORCE_RENDER}
            </div>
          </div>
        );
      } else {
        interestDiv = (
          <div>
            <div className="expanded-ticker-interest">
              Earn ${yearlyInterest.toFixed(2)} per year per share.
            </div>
            <div className="expanded-ticker-interest-sub">
              (${(yearlyInterest / 52).toFixed(3)} per week)
            </div>
          </div>
        );
      }
      return (
        <div>
          <div className="expanded-ticker-orderbook">
            <div className="expanded-ticker-orderbook-title">
              Available Trades
            </div>
            <div className="expanded-ticker-orderbook-content">
              {this.state.orderBook["buy"].slice(-3).map((order) => {
                return (
                  <div key={order.price}>
                    <strong>Sell </strong>
                    {order.numShares} at
                    <strong> ${order.price.toFixed(2)}</strong>
                  </div>
                );
              })}
              {this.state.orderBook["sell"].slice(0, 3).map((order) => {
                return (
                  <div key={order.price}>
                    <strong>Buy </strong>
                    {order.numShares} at
                    <strong> ${order.price.toFixed(2)}</strong>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="expanded-ticker-neworder">
            <div 
              className="account-button expanded-ticker-neworder-button"
              onClick={this.goToMarketOrderButton}
            >
              Place a Market Order
            </div>
            <div 
              className="account-button expanded-ticker-neworder-button"
              onClick={this.goToLimitOrderButton}
            >
              Place a Limit Order
            </div>
          </div>
          {interestDiv}
        </div>
      );
    }

    if (this.props.orderType === "limitorder") {
      return (
        <div className="expanded-ticker-limitmarketorders">
          <div className="expanded-ticker-limitmarketorders-title">
            Place a Limit Order
          </div>
          <div className="expanded-ticker-limitmarketorders-content">
            <div
              className={
                "account-button expanded-ticker-limitmarketorders-buysellbutton " + 
                (this.state.limitOrderIsBuy ? "expanded-ticker-limitmarketorders-buysellbutton-active" : "")
              }
              onClick={(event) => this.limitOrderToggleBuySell(event, "buy")}
            >
              Buy
            </div>
            <div
              className={
                "account-button expanded-ticker-limitmarketorders-buysellbutton " + 
                (!this.state.limitOrderIsBuy ? "expanded-ticker-limitmarketorders-buysellbutton-active" : "")
              }
              onClick={(event) => this.limitOrderToggleBuySell(event, "sell")}
            >
              Sell
            </div>
          </div>
          <div className="expanded-ticker-limitmarketorders-content">
            Price:
            <MaskedInput
              className="expanded-ticker-limitmarketorders-input"
              mask={currencyMask} 
              value={this.state.limitOrderInputPrice}
              onChange={(e) => this.setState({limitOrderInputPrice: e.target.value})}
            />
          </div>
          <div className="expanded-ticker-limitmarketorders-content">
            Number of Shares:
            <MaskedInput
              className="expanded-ticker-limitmarketorders-input"
              mask={naturalMask} 
              value={this.state.limitOrderInputNumShares}
              onChange={(e) => this.setState({limitOrderInputNumShares: e.target.value})}
            />
          </div>
          <div 
            className="account-button expanded-ticker-limitmarketorders-submit"
            onClick={this.submitLimitOrder}
          >
            Submit
          </div>
          <div className="expanded-ticker-limitmarketorders-content">
            {this.state.limitOrderConfirmedMessage}
          </div>
        </div>
      );
    } else {
      return (
        <div className="expanded-ticker-limitmarketorders">
          <div className="expanded-ticker-limitmarketorders-title">
            Place a Market Order
          </div>
          <div className="expanded-ticker-limitmarketorders-content">
            <div
              className={
                "account-button expanded-ticker-limitmarketorders-buysellbutton " + 
                (this.state.marketOrderIsBuy ? "expanded-ticker-limitmarketorders-buysellbutton-active" : "")
              }
              onClick={(event) => this.marketOrderToggleBuySell(event, "buy")}
            >
              Buy
            </div>
            <div
              className={
                "account-button expanded-ticker-limitmarketorders-buysellbutton " + 
                (!this.state.marketOrderIsBuy ? "expanded-ticker-limitmarketorders-buysellbutton-active" : "")
              }
              onClick={(event) => this.marketOrderToggleBuySell(event, "sell")}
            >
              Sell
            </div>
          </div>
          <div className="expanded-ticker-limitmarketorders-content">
            Number of Shares:
            <MaskedInput
              className="expanded-ticker-limitmarketorders-input"
              mask={naturalMask} 
              value={this.state.marketOrderInputNumShares}
              onChange={this.marketOrderSetNumShares}
            />
          </div>
          <div className="expanded-ticker-limitmarketorders-currentprice">
            {this.state.marketOrderCurrentPriceString || FORCE_RENDER}
          </div>
          <div 
            className="account-button expanded-ticker-limitmarketorders-submit"
            onClick={this.submitMarketOrder}
          >
            Submit
          </div>
          <div className="expanded-ticker-limitmarketorders-content">
            {this.state.marketOrderConfirmedMessage}
          </div>
        </div>
      );
    }
  }

  goToLimitOrderButton(event) {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      this.setState({ redirectPath: `/markets/${this.props.ticker}/limitorder` });
    }
  }

  goToMarketOrderButton(event) {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      this.setState({ redirectPath: `/markets/${this.props.ticker}/marketorder` });
    }
  }

  limitOrderToggleBuySell(event, origin) {
    this.setState({ limitOrderIsBuy: origin === "buy" });
  }

  marketOrderToggleBuySell(event, origin) {
    this.setState({ marketOrderIsBuy: origin === "buy" }, this.marketOrderUpdatePrice);
  }

  marketOrderSetNumShares(event) {
    var numShares = event.target.value.replace(",", "")
    this.setState({ marketOrderInputNumShares: numShares }, this.marketOrderUpdatePrice);
  }
  
  marketOrderUpdatePrice() {
    if (this.state.marketOrderInputNumShares === "" || this.state.marketOrderInputNumShares === "0") {
        this.setState({ marketOrderCurrentPriceString: "" });
    } else {
      server.get(
          `/ticker/getCurrentPrice/${this.props.ticker}?numShares=${this.state.marketOrderInputNumShares}&isBuy=${this.state.marketOrderIsBuy ? 1 : 0}`
      ).then((response) => {
        var marketOrderCurrentPriceString;
        if (response.data.ok) {
            var buyOrSell = this.state.marketOrderIsBuy ? "Buy" : "Sell";
            if (this.state.marketOrderInputNumShares === "1") {
              marketOrderCurrentPriceString = 
                `${buyOrSell} 1 share for $${response.data.totalPrice.toFixed(2)}.`
            } else {
              marketOrderCurrentPriceString = 
                `${buyOrSell} ${this.state.marketOrderInputNumShares} shares for $${response.data.totalPrice.toFixed(2)}.`
            }
        } else {
          marketOrderCurrentPriceString = 
            `${response.data.message}`
        }
        this.setState({ marketOrderCurrentPriceString: marketOrderCurrentPriceString });
      })
    }
  }

  submitLimitOrder(event) {
    var price = parseFloat(this.state.limitOrderInputPrice.slice(1).replace(',', ''));
    server.get(
      `/action/createLimitOrder?userId=${this.props.user.sub}&ticker=${this.props.ticker}&price=${price}&numShares=${this.state.limitOrderInputNumShares}&isBuy=${this.state.limitOrderIsBuy ? 1 : 0}`
    ).then(response => {
      this.setState({ limitOrderConfirmedMessage: response.data.message });
      this.props.fetchAccountInfo();
    });
    this.setState({ limitOrderInputNumShares: "", limitOrderInputPrice: "" });
  }

  submitMarketOrder(event) {
    server.get(
      `/action/fillMarketOrder?userId=${this.props.user.sub}&ticker=${this.props.ticker}&numShares=${this.state.marketOrderInputNumShares}&isBuy=${this.state.marketOrderIsBuy ? 1 : 0}`
    ).then((response) => {
      this.setState({ marketOrderConfirmedMessage: response.data.message });
      this.props.fetchAccountInfo();
    });
    this.setState({ marketOrderInputNumShares: "" }, () => {
      this.marketOrderUpdatePrice();
    });
  }

  render() {
    if (this.state.redirectPath !== "") {
      var redirect = <Redirect to={this.state.redirectPath} push={true} />;
      this.setState({ redirectPath: "" })
      return redirect;
    }
    var tickerStyle = {
      backgroundImage: "url(" + tickerToImage[this.props.ticker] + ")"
    };
    return (
      <div 
        className="expanded-ticker"
        style={tickerStyle}
      >
        <div className="expanded-ticker-background-overlay">
          <div className="expanded-ticker-close-icon">
            <IoCloseOutline
              size={this.props.isMobile ? "40" : "60"}
              onClick={this.onClickX}
            />
          </div>
          {this.getTitle()}
          {this.getContent()}
        </div>
      </div>
    );

  }

}

class Ticker extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      tickerInfo: {},
      marketPrices: null,
      redirectPath: "",
    };
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    server.get(`/ticker/getTickerInfo/${this.props.ticker}`).then((response) => {
      this.setState({ tickerInfo: response.data });
    });
    server.get(`/ticker/getMarketPrices/${this.props.ticker}`).then((response) => {
      this.setState({ marketPrices: response.data });
    });
  }

  onMouseOver(event) {
    if (this.props.isAuthenticated) {
      this.setState({ isHovered: true });
    }
  }

  onMouseOut(event) {
    if (this.props.isAuthenticated) {
      this.setState({ isHovered: false });
    }
  }

  onClick(event) {
    if (this.props.isAuthenticated) {
      this.setState({ redirectPath: `/markets/${this.props.ticker}` })
    }
  }

  getInterestDiv() {
    var yearlyInterest = this.state.tickerInfo.yearlyRevenue 
      * this.state.tickerInfo.fractionRevenueReturnedPerShare;
    if (isNaN(yearlyInterest)) {
      return (
        <div className="ticker-interest">
          {FORCE_RENDER}
        </div>
      );
    } else {
      return (
        <div className="ticker-interest">
          Earn ${yearlyInterest.toFixed(2)} per year.
        </div>
      );
    }
  }

  getBuySellDiv() {
    if (!this.state.marketPrices) {
      return (
        <div className="ticker-buysell">
          {FORCE_RENDER}
        </div>
      );
    }
    if (this.state.marketPrices.minSellPrice !== -1) {
      return (
        <div className="ticker-buysell">
          Buy at ${this.state.marketPrices.minSellPrice.toFixed(2)}.
        </div>
      );
    } else {
      return (
        <div className="ticker-buysell">
          No open contracts.
        </div>
      )
    }
  }

  render() {

    if (this.state.redirectPath !== "") {
      var redirect = <Redirect to={this.state.redirectPath} push={true} />;
      this.setState({ redirectPath: "" })
      return redirect;
    }

    var tickerStyle = {
      backgroundImage: "url(" + tickerToImage[this.props.ticker] + ")"
    };
    return (
      <div 
        className={
          "ticker" +
          (this.props.isAuthenticated ? " ticker-pointer" : "") +
          (this.state.isHovered ? " ticker-transform" : "")
        }
        style={tickerStyle}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onClick={this.onClick}
      >
        <div className="ticker-background-overlay">
          <div className="ticker-title">
            <strong>{this.state.tickerInfo.readableName || FORCE_RENDER}</strong>
          </div>
          {this.getInterestDiv()}
          {this.getBuySellDiv()}
        </div>
      </div>
    );

  }

}

class TickersPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      allTickers: [],
      expandedTicker: null,
      orderType: null,
    };
  }

  componentDidMount() {
    server.get("/ticker/getAllTickers").then((response) => {
      this.setState({ allTickers: response.data });
    });
  }

  componentDidUpdate() {
    const { match: { params } } = this.props;
    var newExpandedTicker = (params.expandedTicker === undefined) ? null : params.expandedTicker;
    var newOrderType = (params.orderType === undefined) ? null : params.orderType;
    if (newExpandedTicker !== this.state.expandedTicker) {
      this.setState({ expandedTicker: newExpandedTicker });
    }
    if (newOrderType !== this.state.orderType) {
      this.setState({ orderType: newOrderType });
    }
  }

  render() {
    if (this.state.allTickers.length === 0) {
      return null;
    }
    if (this.state.expandedTicker == null) {
      if (this.props.isMobile) {
        return (
          <div className="tickers">
            {this.state.allTickers.map((ticker) => (
              <GridListTile key={ticker} cols={1}>
                <Ticker
                  ticker={ticker}
                  isAuthenticated={this.props.isAuthenticated}
                />
              </GridListTile>
            ))}
          </div>
        );
      } else {
        return (
          <div className="tickers">
            <GridList className="gridlist" cellHeight="auto" cols={this.props.isMobile ? 1 : 2} >
              {this.state.allTickers.map((ticker) => (
                <GridListTile key={ticker} cols={1}>
                  <Ticker
                    ticker={ticker}
                    isAuthenticated={this.props.isAuthenticated}
                  />
                </GridListTile>
              ))}
            </GridList>
          </div>
        );
      }
    } else {
      if (this.state.allTickers.indexOf(this.state.expandedTicker) === -1) {
        return <Redirect to="/markets" push={true} />;
      }
      if ([null, "marketorder", "limitorder"].indexOf(this.state.orderType) === -1) {
        return <Redirect to={`/markets/${this.state.expandedTicker}`} push={true} />;
      }
      if (this.props.isMobile) {
        return (
          <div className="tickers">
            <GridListTile key={this.state.expandedTicker} cols={1}>
              <ExpandedTicker
                isMobile={this.props.isMobile}
                ticker={this.state.expandedTicker} 
                orderType={this.state.orderType}
                user={this.props.user}
                fetchAccountInfo={this.props.fetchAccountInfo}
              />
            </GridListTile>
          </div>
        );
      } else {
        return (
          <div className="tickers">
            <ExpandedTicker
              isMobile={this.props.isMobile}
              ticker={this.state.expandedTicker} 
              orderType={this.state.orderType}
              user={this.props.user}
              fetchAccountInfo={this.props.fetchAccountInfo}
            />
          </div>
        );
      }
    }
  }

}

TickersPage = withRouter(TickersPage);

const Tickers = (props) => {
  return (
    <TickersPage
      isMobile={props.isMobile}
      user={props.user}
      isAuthenticated={props.isAuthenticated}
      fetchAccountInfo={props.fetchAccountInfo}
    />
  );
}

export default Tickers;


import React from 'react';
import './css/LandingPageAndManifesto.scss';

/*
import backgroundVideo from './img/drone.mp4';
*/
import backgroundImage from './img/cities/sf.png';

import ReactGA from "react-ga";
import { useMediaQuery } from 'react-responsive'
import { Redirect } from "react-router-dom";
import YoutubeBackground from 'react-youtube-background';
import ReactTypingEffect from 'react-typing-effect';
import { ImTwitter } from 'react-icons/im';
import { FiGlobe } from 'react-icons/fi';
import { AiOutlineMail } from 'react-icons/ai';

class Socials extends React.Component {
  render() {
    return (
      <div className="social">
        <a className="social-icon" href="https://twitter.com/chrisliambender" target="_blank" rel="noopener noreferrer">
          <ImTwitter size="25" />
        </a>
        <a className="social-icon" href="https://www.chrisliambender.com/" target="_blank" rel="noopener noreferrer">
          <FiGlobe size="25" />
        </a>
        <a className="social-icon" href="mailto:hello@chlorinated.co" target="_blank" rel="noopener noreferrer">
          <AiOutlineMail size="25" />
        </a>
      </div>
    );
  }
}

class HoverLine extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      shouldRedirect: false,
    };
    this.onMouseEvent = this.onMouseEvent.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onMouseEvent(event) {
    this.setState({isHovered: !this.state.isHovered});
  }

  onClick(event) {
    this.setState({ shouldRedirect: true });
  }

  render() {

    if (this.state.shouldRedirect) {
      var redirect = <Redirect to={this.props.link} push={true} />;
      this.setState({ shouldRedirect: false })
      return redirect;
    }

    return (
      <div 
        className="hover-line-container" 
        onClick={this.onClick}
        onMouseOver={this.onMouseEvent}
        onMouseOut={this.onMouseEvent}
      >
        <div
          className={"hover-line-line line-" + (this.state.isHovered ? "expand" : "contract")}
        />
        <div
          className={"hover-line-text text-" + (this.state.isHovered ? "expand" : "contract")}
        >
          <strong>{this.props.text}</strong>
        </div>
      </div>
    );
  }

}

class CenterText extends React.Component {

  getSubtagline() {
    if (this.props.isMobile) {
      return (
        <div className={"subtagline " + (this.props.isFirstPageview[0] ? "subtagline-delay" : "nodelay")}>
          <p>
            <strong>This For That</strong> lets you bet on cities.
          </p>
          <p>
            Invest in shares of a city and earn dividends from tax revenue.
          </p>
        </div>
      );
    } else {
      return (
        <div className={"subtagline " + (this.props.isFirstPageview[0] ? "subtagline-delay" : "nodelay")}>
          <p>
            <strong>This For That</strong> lets you bet on cities.
            Invest in shares of a city and earn dividends from tax revenue.
          </p>
        </div>
      );
    }
  }

  render () {
    return (
      <div className="center-text">
        <div className={"tagline " + (this.props.isFirstPageview[0] ? "tagline-delay" : "nodelay")}>
          Own Your Share<br />of{' '}
          <ReactTypingEffect 
            text={
              (this.props.isMobile ? [] : ["San Francisco."]).concat([
                "Berkeley.",
                "Miami.",
                "Las Vegas.",
                "Palo Alto.",
                "New York.",
                "Seattle."
              ])
            }
            speed="100"
            eraseSpeed="30"
            typingDelay="0" 
            eraseDelay="2000"
            cursorClassName="cursor" />
        </div>
        {this.getSubtagline()}
        <div className={"hover-lines " + (this.props.isFirstPageview[0] ? "hover-lines-delay" : "nodelay")}>
          <HoverLine
            link="/markets"
            text="Explore Markets"
          />
          <HoverLine
            link="/manifesto"
            text="Read the Manifesto"
          />
        </div>
      </div>
    );
  }
}

const Manifesto = () => {
  return (
    <div className="manifesto-wrapper">
      <div className="manifesto-title">
        This For That
      </div>
      <div className="manifesto-content">
<p>Our government is failing us:</p>

<ul>

<li><p>The FDA <a
    href="https://thedispatch.com/p/fda-career-staff-are-delaying-the"
    target="_blank" rel="noopener noreferrer">took three weeks</a> to run
    statistical analysis on the Pfizer vaccine data while tens of thousands of
    Americans died.</p></li>

<li><p>California <a
    href="https://www.propublica.org/article/they-know-how-to-prevent-megafires-why-wont-anybody-listen"
    target="_blank" rel="noopener noreferrer">lights on fire</a> every fall,
    and it's getting worse: The past three years have seen the state's six
    largest wildfires ever.</p></li>

<li><p>The San Francisco Board of Education <a
    href="https://www.sfchronicle.com/bayarea/article/S-F-school-board-strips-Lowell-High-of-its-15938565.php"
    target="_blank" rel="noopener noreferrer">eliminated admissions
    standards</a> for the city's top-performing high school.</p></li>

<li><p>American Airlines <a
    href="https://viewfromthewing.com/wp-content/uploads/2020/05/AA-hand-sanitizer.pdf"
    target="_blank" rel="noopener noreferrer">had to receive approval</a>
    &nbsp;from the FAA to provide free hand sanitizer to their
    passengers.</p></li>

<li><p>California requires licensed cosmetologists to have <a
    href="https://twitter.com/pitdesi/status/1269704348444942341"
    target="_blank" rel="noopener noreferrer">1600 hours of training</a>.
    Police officers only need 664.</p></li>

<li><p>A two-mile bus route in San Francisco has been&nbsp;<a
    href="https://en.wikipedia.org/wiki/Van_Ness_Bus_Rapid_Transit"
    target="_blank" rel="noopener noreferrer">under construction for seventeen
    years</a>.</p></li>

<li><p>Twenty-two states regulate who is allowed to <a
    href="https://en.wikipedia.org/wiki/Interior_design_regulation_in_the_United_States"
    target="_blank" rel="noopener noreferrer">practice interior
    design</a>.</p></li>

</ul>

<p>What happened to our government? Why is everything so broken?</p>

<p className="center">&#11835;</p>

<p>The budget of San Francisco has <a
  href="https://www.city-journal.org/san-franciscos-municipal-budget"
  target="_blank" rel="noopener noreferrer">increased 50% over the past
  decade</a>, while housing prices, homelessness rates, and property crime
  have&nbsp;<a href="https://projects.sfchronicle.com/2018/sf-car-breakins/"
  target="_blank" rel="noopener noreferrer">not seen any improvement</a>.</p>

<p>Funding is not the issue; our political incentives are broken.</p>

<p>The politicians that are elected are those that maximize electability. You
  win by getting re-elected, not by making good policy.  Playing for the
  long-term is a losing move.</p>

<p>Contrast governments with early-stage startups, where every employee owns a
  share of the company. By giving equity, the company aligns incentives: Each
  employee succeeds only when the company as a whole does well.</p>

<p>When everyone has a stake in the company, the system works for the
  long-term. What would happen if Google made interviews into a lottery?  What
  would happen if Apple stopped releasing any new products? Investors would
  lose confidence in the future of the company, sell their shares, and drive
  down the company's stock price.</p>

<p>To make our political institutions work well, you need to align
  incentives.</p>

<p className="center">&#11835;</p>

<p>In the future, governments will be run like companies.</p>

<p>Instead of raising money through bonds and debt, governments will raise
  money by selling equity. Would we ever have Airbnb or Tesla if startups could
  only get bank loans?</p>

<p>Instead of citizens having little stake in politics, everyone will own
  equity in their city. Would Stripe be so productive if stock options were
  not a part of every employee's compensation package?</p>

<p>Instead of government performance being difficult to measure, governments
  will have clear winners and losers. Public companies' stock prices are so
  indicative of company value because hedge funds continually buy under-valued
  companies and short over-valued ones.  Would Facebook have as much pressure
  to perform if hedge funds weren't constantly predicting the company's
  long-term value?</p>

<p>Instead of every city simply covering the basics, cities will be rewarded
  for exploration and innovation; we will see higher variance in our laws, both
  for the good and the bad.</p>

<p>Instead of stagnating, our government will have real incentives to
  improve.</p>

<p><strong>This For That</strong> aligns incentives in politics by creating
  equity markets in governments.</p>
      </div>
    </div>
  );
}

class LandingPageOrManifesto extends React.Component {
  render() {
    if (window.location.pathname === "/") {
      return (
        <div className="landing-page-or-manifesto landing-page">
          <Socials />
          <CenterText
            isMobile={this.props.isMobile}
            isFirstPageview={this.props.isFirstPageview}
          />
        </div>
      );
    } else if (window.location.pathname === "/manifesto") {
      return (
        <div
          className={
            "landing-page-or-manifesto manifesto" +
            (this.props.isFirstPageview[0] || this.props.isMobile ? "" : " fade-in-right")
          }
        >
          <Manifesto
            isFirstPageview={this.props.isFirstPageview}
          />
        </div>
      );
    } else {
      console.log("Invalid pathname: " + window.location.pathname);
    }
  }
}

const LandingPageAndManifesto = (props) => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const landingPageOrManifesto = (
    <LandingPageOrManifesto
      isMobile={isMobile}
      isFirstPageview={props.isFirstPageview}
    />
  );
  /*
        <video autoPlay loop muted defaultmuted playsInline>
          <source src={backgroundVideo} type='video/mp4' />
        </video>
  */
  if (isMobile) {
    return (
      <div className="landing-page-or-manifesto-container">
        <img src={backgroundImage} alt="" />
        <div className="video-overlay" />
        {landingPageOrManifesto}
      </div>
    )
  } else {
    return (
      <div className="landing-page-or-manifesto-container">
        <YoutubeBackground
          videoId="iZ2-RKF95DM"
          overlay="rgba(0, 0, 0, 0.6)"
          className="youtube"
        />
        {landingPageOrManifesto}
      </div>
    );
  }
}

export default LandingPageAndManifesto;


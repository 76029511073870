import React from 'react';
import './css/App.scss';

import LandingPageAndManifesto from './LandingPageAndManifesto.js';
import Markets from './Markets.js';

import { Auth0Provider } from "@auth0/auth0-react";
import { Router, Switch, Redirect, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import ReactGA from "react-ga";
ReactGA.initialize("UA-175522370-3");

const App = () => {
  const isFirstPageview = [true];
  const history = createBrowserHistory();
  history.listen((location, action) => {
    isFirstPageview[0] = false;
  });
  return (
    <Auth0Provider
      domain="thisforthat.us.auth0.com"
      clientId="sVswOenQcLq1MecbqmmxU1DfmwCm9t3r"
      redirectUri={window.location.origin + "/markets"}>
      <Router history={history}>
        <Switch>
          <Route
            exact path="/"
            render={(props) => (
            <LandingPageAndManifesto {...props} isFirstPageview={isFirstPageview} />
            )}
          />
          <Route
            exact path="/manifesto"
            render={(props) => (
            <LandingPageAndManifesto {...props} isFirstPageview={isFirstPageview} />
            )}
          />
          <Route exact path="/markets" component={Markets} />
          <Route exact path="/markets/:expandedTicker" component={Markets} />
          <Route exact path="/markets/:expandedTicker/:orderType" component={Markets} />
          <Route path="/" render={() => (<Redirect to="/" />)} />
        </Switch>
      </Router>
    </Auth0Provider>
  );
};

export default App;

